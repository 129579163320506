.KwilioImageWrapper {
  .KwilioImage {
    position: relative;
    display: inline-block;
    .KwilioImageMenu {
      position: absolute;
      top: 0;
      right: 0;
      visibility: hidden;
      button {
        padding: 0;
        border: none;
        color: white;
        background-color: transparent;
        cursor: pointer;
        text-transform: none;
        user-select: none;
        display: inline-block;
        svg {
          background-color: none;
        }
      }
    }
    .KwilioImageResizer {
      position: absolute;
      width: 10px;
      height: 10px;
      border: none;
      border-image: initial;
      cursor: nwse-resize;
      &.top-left {
        border-top: 3px solid black;
        border-left: 3px solid black;
        top: 0;
        left: 0;
      }
      &.bottom-right {
        border-bottom: 3px solid black;
        border-right: 3px solid black;
        bottom: 0;
        right: 0;
      }
    }
    img {
      max-width: 100%;
    }
  }
  &.ProseMirror-selectednode {
    .KwilioImage {
      box-shadow: 0 0 0 3px #0342a8;
    }
  }
  &.align-middle {
    text-align: center;
  }
  &.align-left {
    float: left;
    margin-right: 1em;
  }
  &.align-right {
    float: right;
    margin-left: 1em;
  }

  &:hover {
    .KwilioImageMenu {
      visibility: visible;
    }
  }
}
